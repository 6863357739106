import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  text-align: center;
  font-size: 1.8em;
  font-weight: 100;
  padding: 20px;
  span{
    font-weight: 500;
    // color: #34D277;
  }

  ${({theme})=> theme.media.tablet}{
    font-size: 2em;
    max-width: 80vw;
    margin: auto;
  }
`

const InfoStyle = styled.section`
    div{
        padding-bottom: 15px;
        h3{
            // color: #53000D;
            /* text-decoration: underline; */
            font-weight: normal;
        }
    }

    ${({theme})=> theme.media.tablet}{
        max-width: 60vw;
        margin: auto;
  }
`

const ZawodyInfo = () => {
    return (
        <InfoStyle>
            <Title>Najwazniejsze informacje</Title>
            <div>
                <h3>Miasteczo zawodów</h3> Susiec, Park im. Sylwestra Chęcińskiego, ul. Kolejowa 
            </div>
            
            {/* <div>
                <h3>Przed startem w biurze zawodów</h3> 
                <p>Wydawanie pakietów, podpis regulaminu, złożenie depozytu.</p>
                <p>GIGANTE - od  godz. 16.00 do 20.00 / 11.05.2023r, od godz. 6.30 / 12.05.2023r</p>
                <p>CLASSICO - od  godz. 16.00 do 20.00 / 12.05.2023r, od godz. 5.30 / 13.05.2023r</p>
                <p>Odbiór trackera <span>20 minut</span> przed startem przy biurze zawodów.</p>
                
            </div> */}

            <div>
                <h3>Harmonogram</h3>
                <strong><ul>Czwartek 08.05.2025</ul></strong>
                <li>Before party dla dystansu Gigante</li>
                <li>Wydawanie pakietów dla dystansy Gigante</li><br/>

                <strong><ul>Piątek 09.05.2025</ul></strong>
                <li>Start dystansu Gigante</li>
                <li>Before party dla dystansu Classico</li>
                <li>Wydawanie pakietów dla dystansy Classico</li><br/>

                <strong><ul>Sobota 10.05.2025</ul></strong>
                <li>Start dystansu Classico</li>
                <li>Zawody rowerowo-biegowe dla dzieci 4-17lat</li>
                <li>Finish pierwszego zawodnika z dystansu Gigante</li>
                <li>Dekoracja Gigante męzczyzn</li>
                <li>Before party dla dystansu Piccolo</li>
                <li>Finish pierwszego zawodnika z dystansu Classico</li>
                <li>Wydawanie pakietów dla dystansy Piccolo</li><br/>

                <strong><ul>Niedziela 11.05.2025</ul></strong>
                <li>Start dystansu Piccolo</li>
                <li>Dekoracja Classico męzczyzn i kobiet</li>
                <li>Witanie finisherów na mecie</li>

<br/>
                <p>*Grupy będą wypuszczane po maks. 10 osób co 5 minut.</p>
            </div>
            <br/>
            {/* <div>
                <p>GPX, mapa dostępnych parkingów oraz RaceBook (wybrane sklepy na trasie, utrudnienia w ruchu, wrażliwe miejsca itp.)zostaną przesłane na podany przy rejestracji e-mail 7 dni przed startem zawodów.</p>
                <br></br>
                <p>Zapisy zawodników na poszczególne godziny startu zostaną udostępnione na 10 dni przed startem, decyduje kolejność zapisu na listę.</p>
            </div>
         */}
            
        </InfoStyle>
    );
}

export default ZawodyInfo;
